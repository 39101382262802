/**
 * Returns true if expired
 * @returns {boolean}
 */

const isWebinarExpired = (expirationDate, duration = 0) => {
  const durationMilliseconds = 60000 * duration;
  const processedExpirationDate = new Date(expirationDate).getTime() + durationMilliseconds;

  return expirationDate && new Date(processedExpirationDate).getTime() < Date.now();
};

export default isWebinarExpired;
