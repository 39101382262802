/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import ExploreLayout from '../../components/ExploreLayout';
import ExploreTwoColumnDisplay from '../../components/ExploreTwoColumnDisplay';
import isWebinarExpired from '@sprinklr/website/exploreSrc/utils/isWebinarExpired';
import OnDemandThankYouTemplate from '../onDemandThankYouTemplate/OnDemandThankYouTemplate';
import {
  useThankYouParams,
  addThankYouParams,
  checkLocalStorageForURL,
} from '../../utils/paramsUtils';
import { getFeaturedEntries } from '../../utils/getFeaturedEntires';

export const eventPageQuery = graphql`
  query (
    $slug: String!
    $language: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $translationLocale: [String]
  ) {
    ...HeaderFooterSocialQuery
    ...EventQuery
    ...TranslationsFeaturedEventsQuery
    ...AdditionalLocalesFeaturedEventsQuery
    ...ExploreSiteMetadataQuery
    ...LocalesQuery
  }
`;

const EventPageTemplate = ({ data, pageContext }) => {
  const {
    contentfulEvent,
    translationsFeaturedEvents,
    additionalLocalesFeaturedEvents,
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    site,
  } = data;
  const {
    slug,
    eventDuration,
    eventDate,
    onDemandVideoUrl,
    pageTitle,
    onDemandThankYouBody,
    featuredEvents,
    speakers,
    nodeLocale,
    personalization,
  } = contentfulEvent;

  const [isGated, setIsGated] = useState(true);
  const isOnDemand = isWebinarExpired(eventDate, eventDuration);

  useThankYouParams(setIsGated);
  checkLocalStorageForURL(slug, setIsGated);

  const handleFormSubmit = () => {
    setIsGated(false);
    addThankYouParams();
    localStorage.setItem(slug, slug);
    window.scrollTo(0, 0);
  };

  const twoColumnDisplayProps = {
    ...contentfulEvent,
    exploreType: 'EVENT',
    isOnDemand,
    handleFormSubmit,
    isGated,
  };

  const featuredEntriesToRender = getFeaturedEntries({
    translationsFeaturedEntries: translationsFeaturedEvents,
    additionalLocalesFeaturedEntries: additionalLocalesFeaturedEvents,
    featuredEntries: featuredEvents,
    slug,
    dateFieldName: 'eventDate',
  });

  return (
    <ExploreLayout
      context={pageContext}
      slug={`/events/${slug}`}
      metadata={contentfulEvent?.metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
      personalization={personalization}
    >
      {!isGated && isOnDemand && onDemandVideoUrl ? (
        <OnDemandThankYouTemplate
          onDemandVideoUrl={onDemandVideoUrl}
          pageTitle={pageTitle}
          onDemandThankYouBody={onDemandThankYouBody}
          featuredEntries={featuredEntriesToRender}
          speakers={speakers}
          slug={slug}
          nodeLocale={nodeLocale}
        />
      ) : (
        <ExploreTwoColumnDisplay
          {...twoColumnDisplayProps}
          featuredEntries={featuredEntriesToRender}
        />
      )}
    </ExploreLayout>
  );
};

export default EventPageTemplate;
