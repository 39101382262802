/** @jsx jsx */
import { Box, jsx, Themed } from 'theme-ui';
import { Container, RichText, Media } from '@sprinklr/shared-lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import EventSpeakerDisplay from '../../components/EventSpeakerDisplay';
import ExploreCtaBanner from '../../components/ExploreCtaBanner';
import ExploreFeaturedCardfullDisplay from '../../components/ExploreFeaturedCardfullDisplay';
import { OnDemandThankYouTemplateProps } from '../../components/types';

const OnDemandThankYouTemplate: React.FC<
  OnDemandThankYouTemplateProps
> = props => {
  const {
    onDemandVideoUrl,
    pageTitle,
    speakers,
    onDemandThankYouBody,
    featuredEntries,
    nodeLocale,
  } = props;
  const { t } = useTranslation();

  return (
    <Container className={'scn-on-demand-thank-you'}>
      <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
        <Themed.h1
          sx={{ textAlign: 'center', mb: ['30px', '45px', null, '70px'] }}
        >
          {pageTitle}
        </Themed.h1>
        <Media
          mediaType="VIDEO"
          externalVideoUrl={onDemandVideoUrl}
          isFullWidthVideo={true}
        />
        {speakers && (
          <EventSpeakerDisplay
            speakers={speakers}
            isTwoColumnDesktop={true}
            shouldHideBio={true}
          />
        )}
        {onDemandThankYouBody && (
          <Box sx={{ marginTop: ['30px', '40px'] }}>
            <RichText richTextObject={onDemandThankYouBody} />
          </Box>
        )}
        <ExploreCtaBanner
          bannerMessage={t('Discover what Sprinklr can do for you.')}
          nodeLocale={nodeLocale}
        />
      </Box>
      <ExploreFeaturedCardfullDisplay
        headline={t('Featured Events')}
        featuredEntries={featuredEntries}
        cardSlugPrefix="events"
        nodeLocale={nodeLocale}
      />
    </Container>
  );
};

export default OnDemandThankYouTemplate;
